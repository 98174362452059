<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-body>
        <b-row>
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="client"
              dataset-name="projects"
              :filters="filteringPanel.filters"
              :loaded="filteringPanel.loaded"
              :load-dictionaries="loadDictionaries"
              @dict-loaded="onFilteringPanelLoad"
              @loaded="onFilteringPanelLoad"
              @search="getData"
              @change="filterData"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <projects-table ref="projects" />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from "@/components/FilteringPanel";

import ProjectsTable from "@/views/ProjectManagement/ProjectsTable";

export default {
  name: "Projects",
  components: {
    FilteringPanel,
    ProjectsTable
  },
  data: function() {
    return {
      isLoading: false,
      filteringPanel: {
        loaded: false,
        selected: {},
        filters: [
          {
            type: "select",
            dataType: "string",
            title: "Project Name",
            name: "project",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [],
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            title: "Status",
            name: "status",
            trackby: "id",
            label: "label",
            multiple: false,
            options: [
              {
                id: 1,
                label: "Active"
              },
              {
                id: 2,
                label: "Completed"
              },
              {
                id: 3,
                label: "All"
              }
            ],
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            title: "Assigned To",
            name: "assigned_to",
            trackby: "id",
            label: "label",
            options: [],
            multiple: true,
            selected: {},
            group_values: "group_values",
            group_label: "group_label"
          },
          {
            type: "daterange",
            dataType: "datetime",
            defaultRange: "7 years",
            title: "Start Date",
            name: "start-date"
          },
          {
            type: "daterange",
            dataType: "datetime",
            defaultRange: "7 years",
            title: "Finish Date",
            name: "finish-date"
          }
        ]
      },
      assignedTo: {
        options: []
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    async loadDictionaries() {
      const users = async () => {
        let self = this;

        this.$api.get("users").then(users => {
          this.$api.get("groups").then(groups => {
            self.assignedTo.options = [
              {
                group_label: "Groups",
                group_values: groups.map(u => ({
                  id: u.groupid.toString(),
                  label: u.groupname.trim()
                }))
              },
              {
                group_label: "Users",
                group_values: users.map(u => ({
                  id: u.id.toString(),
                  label: u.full_name.trim()
                }))
              }
            ];

            self.filteringPanel.filters.find(
              f => f.name === "assigned_to"
            ).options = self.assignedTo.options;
          });
        });
      };

      const projects = async () => {
        let projects = await this.$api.get("dictionaries/projects");

        this.filteringPanel.filters.find(
          f => f.name === "project"
        ).options = projects.map(u => ({
          id: u.id,
          label: u.name
        }));
      };

      Promise.all([users(), projects()]).then(() => {
        this.filteringPanel.loaded = true;
      });
    },
    onFilteringPanelLoad() {
      this.$refs["projects"].getData();
    },
    onCustomFilterSelected(e) {
      if (e.data) this.onSelectedExpression(e);
    },

    async onSelectedExpression(e) {
      //if selected expression is empty then apply usual filters
      if (!e || !e.data) {
        this.filterData(this.filteringPanel.selected);
        return;
      }

      let rawData = this.$refs["projects"].rawData;

      if (rawData.length === 0) {
        await this.$form.msgBoxOk("No data available!");
        return;
      }
      let expressions = JSON.parse(e.data);

      if (!expressions) {
        self.$form.makeToastError("Invalid expression");
        return;
      }

      let filteredData = this.$helpers.applyExpressions2Dataset(
        expressions,
        rawData
      );

      this.$refs["projects"].dataTable.dataSet = filteredData;

      this.presetFilteredData = filteredData;
    },
    onFilteringPanelStateChange() {
      //this.isDataVisible = !this.$refs.filteringPanel.expressionBuilder.visible
    },

    filterData(e) {
      this.filteringPanel.selected = e;

      if (!this.$refs["projects"].rawData.length) return;

      let filteredData = this.$refs["projects"].rawData;

      filteredData = filteredData.filter(i =>
        this.filteringPanel.selected.project &&
        this.filteringPanel.selected.project.length > 0
          ? this.filteringPanel.selected.project.find(
              s => s.label === i["Project Name"]
            )
          : true
      );

      filteredData = filteredData.filter(i =>
        this.filteringPanel.selected.assigned_to &&
        this.filteringPanel.selected.assigned_to.length > 0
          ? this.filteringPanel.selected.assigned_to.find(s =>
              i["Assigned To"].includes(s.label)
            )
          : true
      );

      filteredData = filteredData.filter(i =>
        this.filteringPanel.selected["start-date"]
          ? !i["Start Date"] ||
            (new Date(i["Start Date"]) >=
              new Date(this.filteringPanel.selected["start-date"].startDate) &&
              new Date(i["Start Date"]) <=
                new Date(this.filteringPanel.selected["start-date"].endDate))
          : true
      );

      filteredData = filteredData.filter(i =>
        this.filteringPanel.selected["finish-date"]
          ? !i["Finish Date"] ||
            (new Date(i["Finish Date"]) >=
              new Date(this.filteringPanel.selected["finish-date"].startDate) &&
              new Date(i["Finish Date"]) <=
                new Date(this.filteringPanel.selected["finish-date"].endDate))
          : true
      );

      let self = this;

      filteredData = filteredData.filter(function(i) {
        if (!self.filteringPanel.selected.status) return true;
        let s = self.filteringPanel.selected.status.label;

        let p = parseFloat(i["Progress"]);

        if (s === "Active") if (p > 0 && p < 100) return true;
        if (s === "Completed") if (p === 100) return true;
        if (s === "All") return true;
        return false;
      });

      this.$refs["projects"].dataTable.dataSet = filteredData;
    },
    getData(e) {
      if (e) this.filteringPanel.selected = e;

      this.$refs["projects"].getData();
    }
  },
  watch: {}
};
</script>

<style>
.fa-toolbar {
  color: #0000008a;
}

#VueTables_th--Progress {
  width: 300px;
}
</style>
